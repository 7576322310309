import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import { MainBlogTitle, PrePost } from "../styled-components/index"

export default ({ pageContext: { frontmatter, html } }) => {
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <MainBlogTitle>{frontmatter.title}</MainBlogTitle>
      <PrePost>
        <time>{new Date(frontmatter.date).toDateString().toUpperCase()}</time>
      </PrePost>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}
